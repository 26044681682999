import "./styles/main.scss";

import { createApp } from "vue";
import IconListArrow from "./components/icons/ListArrow.vue";
import IconMobileMenu from "./components/icons/MobileMenu.vue";
import IconCloseMenu from "./components/icons/CloseMenu.vue";
import StdLink from "./components/StdLink.vue";
import ListItem from "./components/ListItem.vue";
import ContactsMap from "./components/ContactsMap.vue";
import MobileHeader from "./components/MobileHeader.vue";

window.Application = window.Application || {};

function initApp() {
  const el = document.querySelector(".vue-root");
  if (!el) {
    return;
  }

  const links = el.getElementsByTagName("a");
  for (let link of links) {
    if (!link.hasAttribute("is")) {
      link.setAttribute("is", "vue:std-link");
    }
  }

  const template = el.innerHTML;

  const app = createApp({
    data() {
      return {};
    },
    mounted() {
      // Вычисление толщины скроллбара браузера
      setTimeout(() => {
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll";
        outer.style.msOverflowStyle = "scrollbar";
        document.body.appendChild(outer);
        const inner = document.createElement("div");
        outer.appendChild(inner);
        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
        outer.parentNode.removeChild(outer);

        document.documentElement.style.setProperty(
          "--scrollbar-width",
          scrollbarWidth + "px"
        );
      }, 50);
    },
    template: template,
  });

  app.directive("hscroll-edges", {
    mounted(element) {
      const hscroller = element.firstChild;
      const child = hscroller.firstChild;
      function updateScroll() {
        const scroll = hscroller.scrollLeft;
        const width = hscroller.offsetWidth;
        const childWidth = child.offsetWidth;

        if (scroll == 0) {
          element.classList.add("hscroll-begin");
        } else {
          element.classList.remove("hscroll-begin");
        }

        if (scroll == childWidth - width) {
          element.classList.add("hscroll-end");
        } else {
          element.classList.remove("hscroll-end");
        }
      }

      updateScroll();
      hscroller.addEventListener("scroll", updateScroll, { passive: true });
      hscroller.addEventListener("resize", updateScroll, { passive: true });
    },
  });

  app.mixin({
    methods: {
      $s(name) {
        return (window.cms_strings || {})[name] || "";
      },
    },
  });

  app.component("IconListArrow", IconListArrow);
  app.component("IconMobileMenu", IconMobileMenu);
  app.component("IconCloseMenu", IconCloseMenu);
  app.component("StdLink", StdLink);
  app.component("ListItem", ListItem);
  app.component("ContactsMap", ContactsMap);
  app.component("MobileHeader", MobileHeader);

  app.mount(el);
}

if (window.document.readyState == "loading") {
  window.document.addEventListener("DOMContentLoaded", initApp);
} else {
  initApp();
}
