<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="currentColor"
  >
    <path
      d="M14.75 29.084a1.542 1.542 0 0 1-.354-.917 1.128 1.128 0 0 1 .354-.875l7.333-7.334-7.375-7.374c-.222-.223-.326-.521-.312-.896.014-.375.132-.674.354-.896.278-.278.576-.41.896-.396.32.014.604.146.854.396l8.292 8.292c.139.138.236.277.291.416.056.14.084.292.084.459 0 .166-.028.319-.084.458a1.27 1.27 0 0 1-.291.416l-8.25 8.25c-.25.25-.542.369-.875.355a1.542 1.542 0 0 1-.917-.354Z"
    />
  </svg>
</template>
