<script>
export default {
  name: "MobileHeader",
  data() {
    return {
      isOpen: false,
      isHeaderVisible: true,
    };
  },
  watch: {
    isOpen(open) {
      if (open) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
  mounted() {
    let prevScrollY = window.scrollY;
    window.addEventListener("scroll", () => {
      const scrollY = window.scrollY;

      if (scrollY < 60) {
        this.isHeaderVisible = true;
        prevScrollY = scrollY;
      } else if (Math.abs(scrollY - prevScrollY) > 30) {
        if (scrollY > prevScrollY) {
          this.isHeaderVisible = false;
        } else {
          this.isHeaderVisible = true;
        }

        prevScrollY = scrollY;
      }
    });
  },
};
</script>

<template>
  <div class="mobile-header" :class="{ show: isHeaderVisible || isOpen }">
    <div class="left">
      <button type="button" class="menu-button" @click="toggle">
        <transition name="fade"
          ><icon-close-menu v-if="isOpen" class="icon"
        /></transition>
        <transition name="fade"
          ><icon-mobile-menu v-if="!isOpen" class="icon"
        /></transition>
      </button>
    </div>
    <div class="center">
      <slot name="default"></slot>
    </div>
    <div class="right">
      <slot name="lang"></slot>
    </div>
  </div>
  <transition name="menu">
    <div v-if="isOpen" class="menu-overlay" @click="close">
      <div class="mobile-menu" @click.stop>
        <div class="mobile-menu-content">
          <div class="menu-wrap" @click="close">
            <slot name="menu"></slot>
          </div>
          <div class="contacts">
            <slot name="contacts"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.mobile-header {
  @extend %flex-row-start-center;

  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: $color-background-5;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
  padding: 0 var(--page-margins) 0 var(--page-margins);
  transform: translateY(-61px);
  transition: transform 0.3s;
  display: none;

  @media screen and (max-width: 1000px) {
    display: flex;
  }

  &.show {
    transform: translateY(0);
  }

  .center {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .left,
  .right {
    @extend %flex-row-start-center;

    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;
  }

  .right {
    justify-content: flex-end;

    &:deep .lang {
      color: $color-text-grey;
    }
  }

  &:deep .logo {
    display: block;
    height: 40px;
  }

  .menu-button {
    position: relative;
    padding: 0;
    border: none;
    background: none;
    width: 32px;
    height: 32px;

    .icon {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      color: #fff;
      width: 32px;
      height: 32px;
    }
  }
}

.menu-overlay {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-background-5, 0.75);
  z-index: 90;

  .mobile-menu {
    position: absolute;
    width: 100%;
    max-width: 320px;
    background: #fff;
    left: 0;
    top: 1px;
    bottom: 0;
    overflow: auto;
  }

  .mobile-menu-content {
    min-height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  .menu-wrap {
    margin-bottom: auto;
  }

  .contacts {
    padding: 25px;
  }

  &:deep .menu-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .menu-item {
      border-bottom: 1px solid $color-border;
    }

    .menu-link {
      display: block;
      padding: 11px 25px 11px 25px;
      font-size: 18px;
    }
  }

  &:deep .phones {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &:deep .phone {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    min-height: 40px;
  }

  &:deep .tel {
    font-weight: 700;
    font-size: 22px;
    color: $color-text;
    cursor: default;
  }

  &:deep .whatsapp {
    margin-left: 16px;
    transition: filter 0.3s;

    .image {
      display: block;
      width: 30px;
      height: 30px;
    }

    &:hover {
      filter: brightness(1.15);
    }
  }

  &:deep .emails {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
  }

  &:deep .email {
    font-size: 18px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.5s ease-in-out;

  .mobile-menu {
    transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
  }
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;

  .mobile-menu {
    transform: translateX(-50px);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
