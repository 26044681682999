<script>
import { nanoid } from "nanoid";

export default {
  name: "FaqItem",
  props: {
    initOpen: {
      type: Boolean,
      default: false,
    },
    singleLineTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: nanoid(),
      isOpen: this.initOpen,
      height: "60px",
    };
  },
  watch: {
    isOpen() {
      this.updateHeight();
    },
  },
  /*
  computed: {
    height() {
      if (this.isLoaded) {
        if (this.isOpen) {
          return this.$refs.wrapper.offsetHeight + "px";
        } else {
          return this.$refs.itemTitle.offsetHeight + "px";
        }
      } else {
        return "60px";
      }
    },
  },
  */
  methods: {
    updateHeight() {
      if (this.isOpen) {
        this.height = this.$refs.wrapper.offsetHeight + "px";
      } else {
        this.height = this.$refs.itemTitle.offsetHeight + "px";
      }
    },
    toggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        const folderopen = new CustomEvent("folderopen", {
          detail: {
            listItemId: this.id,
          },
        });

        window.dispatchEvent(folderopen);
      }
    },
  },
  mounted() {
    window.addEventListener("folderopen", (event) => {
      if (event.detail.listItemId != this.id) {
        this.isOpen = false;
      }
    });

    window.addEventListener("load", (/*event*/) => {
      this.updateHeight();

      setTimeout(() => {
        this.updateHeight();
      }, 100);

      setTimeout(() => {
        this.updateHeight();
      }, 1000);

      setTimeout(() => {
        this.updateHeight();
      }, 3000);

      setTimeout(() => {
        this.updateHeight();
      }, 10000);
    });

    this.updateHeight();
  },
};
</script>

<template>
  <div class="list-item" :style="{ height: height }">
    <div ref="wrapper">
      <div class="title" ref="itemTitle" @click="toggle">
        <div class="title-text" :class="{ nowrap: singleLineTitle }">
          <slot name="title" />
        </div>
        <icon-list-arrow
          class="arrow"
          :class="{ open: isOpen }"
        ></icon-list-arrow>
      </div>
      <article class="text">
        <slot name="text"></slot>
      </article>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-item {
  overflow: hidden;
  border-bottom: 1px solid $color-text;
  transition: height 0.3s;
}

.title {
  @extend %flex-row-start-center;
  min-height: 59px;
  box-sizing: border-box;
  padding-top: 17.5px;
  padding-bottom: 17.5px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: $color-brand-1;
  }
}

.title-text {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 500;

  &.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.arrow {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 20px;
  transition: transform 0.3s;
  margin-top: -15px;
  margin-bottom: -15px;

  &.open {
    transform: rotate(90deg);
  }
}

.text {
  padding-bottom: 30px;
}

/*
.faq-item {
  overflow: hidden;
  transition: height 0.3s;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid $color-border;
}

.faq-question {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  align-items: left;
  height: 60px;
  padding-right: 50px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;

  .svg-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px;
    transition: transform 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.faq-answer {
  padding-bottom: 20px;
}
*/
</style>
