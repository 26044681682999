<script>
export default {
  name: "ContactsMap",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    window.DG.then(() => {
      this.map = window.DG.map(this.$el, {
        fullscreenControl: false,
        center: this.options.center,
        zoom: this.options.zoom,
      });

      window.DG.marker(this.options.coord)
        .addTo(this.map)
        .bindPopup(this.options.description);
    });
  },
};
</script>

<template>
  <div class="contacts-map"></div>
</template>

<style lang="scss" scoped></style>
